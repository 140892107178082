<template>
    <div class="base-box">

        <el-form ref="form" label-width="140px">
            <el-row v-if="isUpdate">
                <el-col :span="12">
                    <el-form-item label="代理商编号">
                        {{agentNo}}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="代理商名称">
                        {{agentName}}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-col :span="12">
                <el-form-item label="返现代付开关">
                    <el-select v-model="payType" disabled placeholder="请选择开关">
                        <el-option label="开启" value="1"></el-option>
                        <el-option label="关闭" value="0"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            
            <div style="text-align:right;" v-if="isUpdate">
                <el-button  @click="addActive">新增活动</el-button>
            </div>
            <el-form-item label-width="0" required>
                <el-table
                    ref="multipleTable"
                    :data="agentActivityInfoVoList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column
                    v-if="!isUpdate"
                    type="selection"
                    label="全选"
                    width="55">
                    </el-table-column>
                    <el-table-column
                    label="活动名称"
                    prop="activityName">
                    </el-table-column>
                    <el-table-column
                    prop="policyName"
                    label="费率政策"
                   >
                    </el-table-column>
                    <!-- <el-table-column prop="status" label="状态"  v-if="isUpdate">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.status" placeholder="请选择状态" style="width: 80px">
                                <el-option label="启用" value="ENABLE"></el-option>
                                <el-option label="禁用" value="DISABLE"></el-option>
                            </el-select>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="激活返现" v-if="isUpdate && hasActiveRecash">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.activeRecash" :name="'activeRecash' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxActiveRecash" v-if="!isBlank(scope.row.maxActiveRecash)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="重复激活返现" v-if="isUpdate && hasRepeatActiveRecash">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.repeatActiveRecash"
                                :name="'repeatActiveRecash' + scope.$index" @getStatus="getStatus" :minVal="0"
                                :maxVal="scope.row.maxRepeatActiveRecash" v-if="!isBlank(scope.row.maxRepeatActiveRecash)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现1" v-if="isUpdate && hasStandardRecash1">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash1" :name="'standardRecash1' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash1"
                                v-if="!isBlank(scope.row.maxStandardRecash1)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现2" v-if="isUpdate && hasStandardRecash2">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash2" :name="'standardRecash2' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash2"
                                v-if="!isBlank(scope.row.maxStandardRecash2)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="达标返现3" v-if="isUpdate && hasStandardRecash3">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.standardRecash3" :name="'standardRecash3' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxStandardRecash3"
                                v-if="!isBlank(scope.row.maxStandardRecash3)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现1" v-if="isUpdate && hasFlowRecash1">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash" :name="'flowRecash' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash"
                                v-if="!isBlank(scope.row.maxFlowRecash)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现2" v-if="isUpdate && hasFlowRecash2">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash2" :name="'flowRecash2' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash2"
                                v-if="!isBlank(scope.row.maxFlowRecash2)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="4G返现3" v-if="isUpdate && hasFlowRecash3">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.flowRecash3" :name="'flowRecash3' + scope.$index"
                                @getStatus="getStatus" :minVal="0" :maxVal="scope.row.maxFlowRecash3"
                                v-if="!isBlank(scope.row.maxFlowRecash3)"></CashBackInput><span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="税点" v-if="isUpdate">
                        <template slot-scope="scope">
                            <CashBackInput v-model="scope.row.taxPoint" :name="'taxPoint' + scope.$index"
                                @getStatus="getStatus" :minVal="scope.row.minTaxPoint" :maxVal="scope.row.maxTaxPoint"
                                v-if="canChangeTaxPoint"></CashBackInput><span v-else>{{ scope.row.taxPoint }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
        </el-form>
        <el-row :gutter="20" v-if="!isUpdate">
            <el-col :span="12" :offset="6">
                <el-button  type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary"  @click="nextClick()">提交</el-button>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isUpdate">
            <el-col :span="12" :offset="6">
                <el-button  @click="closeClick">关闭</el-button>
                <el-button type="primary" :loading="loading" :disabled="!(disabled=='')"  @click="updateClick">提交</el-button>
            </el-col>
        </el-row>
        <el-dialog 
        :visible.sync="acitveVisible" append-to-body
        width="1100px">
            <template>
                <el-table stripe border :data="notHasPolicyInfoList" style="width: 100%"
                    @selection-change="notSelectionChange">
                    <el-table-column type="selection" label="全选" width="55">
                    </el-table-column>
                    <el-table-column prop="activityName" label="活动名称" />
                    <el-table-column prop="activityRemark" label="费率政策" />
                </el-table>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button  @click="acitveVisible = false">取 消</el-button>
                <el-button type="primary"  @click="addNewPolicy">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
  import CashBackInput from '@/components/cashBackInput'
  import { AgentApi } from '@/api'
  export default {
    data() {
        return {
            agentActivityInfoVoList: [],
            payType: '1',
            multipleSelection: [],
            newSelection: [],
            notHasPolicyInfoList: [],
            oldList: [],
            acitveVisible: false,
            loading: false,
            disabled: "",
            hasFlowRecash1: false,
            hasFlowRecash2: false,
            hasFlowRecash3: false,
            hasStandardRecash1: false,
            hasStandardRecash2: false,
            hasStandardRecash3: false,
            hasActiveRecash: false,
            hasRepeatActiveRecash: false,
            canChangeTaxPoint: false,
        }
    },
    props: {
        parentAgentNo: {
            type: [Number, String],
            default: 0
        },
        type:{
            type:String,
            default: 'org'
        },
        isUpdate:{
            type: Boolean,
            default: false
        },
        agentNo: {
            type: [Number, String],
            default: 0
        },
        agentName:{
            type:String,
            default: ''
        },
        show:{
            type: Boolean,
            default:false
        }
    },
    watch:{
       show: {
           handler(val){
               if(val){
                this.agentActivityInfoVoList = []
                this.multipleSelection = []
                this.isUpdate ? this.getUpdateList() : this.getList()
            }
           },
           immediate:true
       },
       parentAgentNo: {
           handler(val){
            this.agentActivityInfoVoList = []
            this.multipleSelection = []
            this.isUpdate ? this.getUpdateList() : this.getList()
           },
           immediate:true
       }
    },
    components:{
        CashBackInput
    },
    created(){
        this.isUpdate ? '' : this.getList()
    },
    methods:{
        addActive(){
            this.acitveVisible = true
        },
        addNewPolicy(){
           if(!this.newSelection){
               this.agentActivityInfoVoList = this.oldList
           } else{
               let list = JSON.parse(JSON.stringify(this.oldList))
               list = list.concat(this.newSelection)
               this.agentActivityInfoVoList = list
           }
           this.acitveVisible = false
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        notSelectionChange(val) {
            this.newSelection = val;
        },
        getList(){
            AgentApi.beforeNewAgent()
                .then(res=>{
                    if(res.success){
                        this.agentActivityInfoVoList = res.data.agentActivityInfoVoList
                    } else{
                        this.agentActivityInfoVoList = ''
                    }
                })
        },
        getUpdateList(){
            AgentApi.beforeUpdateAgentActivity(this.agentNo)
                .then(res=>{
                    if(res.success){
                        this.agentActivityInfoVoList = res.data.hasPolicyInfoList
                        this.payType = res.data.agentInfoDo ? res.data.agentInfoDo.cashbackSwitch : '1'
                        this.oldList = res.data.hasPolicyInfoList ? JSON.parse(JSON.stringify(res.data.hasPolicyInfoList)) : []
                        this.notHasPolicyInfoList = res.data.notHasPolicyInfoList
                        this.canChangeTaxPoint = res.data.canChangeTaxPoint
                        for (let item of this.agentActivityInfoVoList) {
                            if (!this.isBlank(item.maxFlowRecash)) {
                                this.hasFlowRecash1 = true
                            }
                            if (!this.isBlank(item.maxFlowRecash2)) {
                                this.hasFlowRecash2 = true
                            }
                            if (!this.isBlank(item.maxFlowRecash3)) {
                                this.hasFlowRecash3 = true
                            }
                            if (!this.isBlank(item.maxStandardRecash1)) {
                                this.hasStandardRecash1 = true
                            }
                            if (!this.isBlank(item.maxStandardRecash2)) {
                                this.hasStandardRecash2 = true
                            }
                            if (!this.isBlank(item.maxStandardRecash3)) {
                                this.hasStandardRecash3 = true
                            }
                            if (!this.isBlank(item.maxRepeatActiveRecash)) {
                                this.hasRepeatActiveRecash = true
                            }
                            if (!this.isBlank(item.maxActiveRecash)) {
                                this.hasActiveRecash = true
                            }
                        }
                    }
                })
        },
        isBlank(obj) {
            return obj == "0" || obj === null || obj === undefined || obj === "" || (Array.isArray(obj) && obj.length === 0)
        },
        changeCollapse(val) {
            console.log(val)
        },
        changePolicy(a, b) {
            console.log(a)
            console.log(b)
        },
        closeClick() {
            this.$emit("change", true)
        },
        async updateClick() {
            this.loading = true
            let param = { agentActivityInfoDtoList: '' }
            param.agentActivityInfoDtoList = this.agentActivityInfoVoList
            let result = await AgentApi.updateAgentCashBack(this.agentNo,param)
            if(result.success){
                this.Message.success("修改成功")
                this.$emit("change", true)
            }
            this.loading = false
        },
        nextClick(){
            if(this.multipleSelection.length<=0){
                this.Message("请选择返现配置")
                return
            }
            console.log(this.multipleSelection)
            this.$emit('change',this.multipleSelection,'submit')
        },
        prevClick(){
            this.$emit('change','','prev')
        },
        getStatus(name,status){
            if(status=='success'){
                this.disabled = this.disabled.replace(name,"")
            } else{
                if(this.disabled.indexOf(name)>-1){
                    return
                }
                 this.disabled+=name
            }
        }
    }
  }
</script>