<template>
	<div class="base-box">
		<el-form
			ref="policyInfoform"
			:model="policyInfoList"
			label-width="140px"
		>
			<el-row v-if="isUpdate">
				<el-col :span="10">
					<el-form-item label="代理商编号">
						{{ agentNo }}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="代理商名称">
						{{ agentName }}
					</el-form-item>
				</el-col>
			</el-row>
			<el-col :span="12">
				<el-form-item label="交易分润代付开关">
					<el-select
						v-model="paySwitch"
						placeholder="请选择开关"
						disabled
					>
						<el-option label="开启" value="1"></el-option>
						<el-option label="关闭" value="0"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12">
				<el-form-item label="笔数分润代付开关">
					<el-select
						v-model="settleProfitSwitch"
						placeholder="请选择开关"
						disabled
					>
						<el-option label="开启" value="1"></el-option>
						<el-option label="关闭" value="0"></el-option>
					</el-select>
				</el-form-item>
			</el-col>
			<el-col :span="12" v-if="isUpdate">
				<el-form-item label="分润提现费">
					<template>
						<CashBackInput
							v-model="withdrawalFee"
							:minVal="minWithdrawalFee"
							:maxVal="maxWithdrawalFee"
						></CashBackInput>
					</template>
				</el-form-item>
			</el-col>
		</el-form>
		<div style="clear:both;"></div>
		<div style="text-align:right; margin-bottom:10px" v-if="isUpdate">
			<el-button @click="addActive">新增政策</el-button>
		</div>
		<div v-for="item in costList" :key="item.startCreateTime">
			<Cost
				@on-change="changePolicy"
				:costList="item"
				:isUpdate="isUpdate"
				:canChangeTaxPoint="canChangeTaxPoint"
				:agentInfo="agentInfo"
			></Cost>
		</div>
		<el-row :gutter="20" v-if="!isUpdate">
			<el-col :span="12" :offset="6">
				<el-button type="primary" @click="prevClick">上一步</el-button>
				<el-button type="primary" @click="nextClick('policyInfoform')"
					>下一步</el-button
				>
			</el-col>
		</el-row>
		<el-row :gutter="20" v-if="isUpdate">
			<el-col :span="12" :offset="6">
				<el-button @click="closeClick">关闭</el-button>
				<el-button
					type="primary"
					:loading="loading"
					:disabled="!(disabled == '')"
					@click="updateClick"
					>提交</el-button
				>
			</el-col>
		</el-row>
		<el-dialog :visible.sync="acitveVisible" append-to-body width="1100px">
			<template>
				<el-table
					stripe
					border
					:data="notHasPolicyInfoList"
					style="width: 100%"
					@selection-change="notSelectionChange"
				>
					<el-table-column type="selection" label="全选" width="55">
					</el-table-column>
					<el-table-column prop="policyName" label="费率政策" />
					<el-table-column prop="policyRemark" label="说明" />
				</el-table>
			</template>
			<span slot="footer" class="dialog-footer">
				<el-button @click="acitveVisible = false">取 消</el-button>
				<el-button type="primary" @click="addNewPolicy">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import Cost from "./cost";
import CashBackInput from "@/components/cashBackInput";
import { AgentApi } from "@/api";
export default {
	data() {
		return {
			policyInfoList: {},
			costList: [],
			paySwitch: "1",
			settleProfitSwitch: "1",
			checkList: [],
			loading: false,
			newSelection: [],
			taxPointList: [],
			notHasPolicyInfoList: [],
			oldList: [],
			acitveVisible: false,
			taxPoint: "8",
			disabled: "",
			canChangeTaxPoint: false,
			// 代理商编号
			agentInfo: {}
		};
	},
	components: {
		Cost,
		CashBackInput
	},
	props: {
		parentAgentNo: {
			type: [Number, String],
			default: 0
		},
		type: {
			type: String,
			default: "org"
		},
		isUpdate: {
			type: Boolean,
			default: false
		},
		agentNo: {
			type: [Number, String],
			default: 0
		},
		agentName: {
			type: String,
			default: ""
		},
		agentLevel: {
			type: [String, Number],
			default: ""
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		show: {
			handler(val) {
				if (val) {
					this.costList = [];
					this.isUpdate ? this.getUpdateList() : this.getList();
				}
			},
			immediate: true
		},
		parentAgentNo: {
			handler(val) {
				if (val) {
					this.costList = [];
					this.isUpdate ? this.getUpdateList() : this.getList();
				}
			},
			immediate: true
		}
	},
	created() {
		this.isUpdate ? "" : this.getList();
	},
	methods: {
		addActive() {
			this.acitveVisible = true;
		},
		addNewPolicy() {
			if (!this.newSelection) {
				this.costList = this.oldList;
			} else {
				let list = JSON.parse(JSON.stringify(this.oldList));
				list = list.concat(this.newSelection);
				this.costList = list;
			}
			this.acitveVisible = false;
		},
		notSelectionChange(val) {
			this.newSelection = val;
		},
		getList() {
			AgentApi.beforeNewAgent().then(res => {
				if (res.success) {
					this.costList = res.data.policyInfoList;
				} else {
					this.costList = "";
				}
			});
		},
		getUpdateList() {
			AgentApi.beforeUpdateAgentCost(this.agentNo).then(res => {
				// 代理商编号传给子组件
				this.agentInfo = res.data.agentInfoDo;
				if (res.success) {
					this.costList = res.data.hasPolicyInfoList;
					this.withdrawalFee = res.data.agentInfoDo
						? res.data.agentInfoDo.withdrawalFee
						: 0;
					this.maxWithdrawalFee = res.data.maxWithdrawalFee
						? res.data.maxWithdrawalFee
						: 0;
					this.minWithdrawalFee = res.data.minWithdrawalFee
						? res.data.minWithdrawalFee
						: 0;
					this.paySwitch = res.data.agentInfoDo
						? res.data.agentInfoDo.profitSwitch
						: "1";
					this.settleProfitSwitch = res.data.agentInfoDo
						? res.data.agentInfoDo.settleProfitSwitch
						: "1";
					this.oldList = res.data.hasPolicyInfoList
						? JSON.parse(JSON.stringify(res.data.hasPolicyInfoList))
						: [];
					this.notHasPolicyInfoList = res.data.notHasPolicyInfoList;
					this.canChangeTaxPoint = res.data.canChangeTaxPoint;
					for (let item of this.costList) {
						if (item.maxTaxPoint) {
							item.taxPointList = [];
							for (
								let i = parseInt(item.minTaxPoint);
								i < parseInt(item.maxTaxPoint) + 1;
								i++
							) {
								item.taxPointList.push({
									text: i + "%",
									value: i
								});
							}
						}
					}
					for (let item of this.notHasPolicyInfoList) {
						if (item.maxTaxPoint) {
							item.taxPointList = [];
							for (
								let i = parseInt(item.minTaxPoint);
								i < parseInt(item.maxTaxPoint) + 1;
								i++
							) {
								item.taxPointList.push({
									text: i + "%",
									value: i
								});
							}
						}
					}
				}
			});
		},
		changeCollapse(val) {
			console.log(val);
		},
		changePolicy(check, params, name, status) {
			
			if (check) {
				this.checkList.push(params);
			} else {
				var index = this.checkList.indexOf(params);
				if (index > -1) {
					this.checkList.splice(index, 1);
				}
			}
			if (status == "success") {
				this.disabled = this.disabled.replace(name, "");
			} else {
				if (this.disabled.indexOf(name) > -1) {
					return;
				}
				this.disabled += name;
			}
		},
		closeClick() {
			this.$emit("change", true);
		},
		async updateClick() {
			if (!this.withdrawalFee) {
				this.Message.error("请输入分润提现费");
				return;
			}
			this.loading = true;
			let params = { policyInfoList: "", taxPoint: this.taxPoint };
			params.policyInfoList = this.costList;
			params.withdrawalFee = this.withdrawalFee;
			let result = await AgentApi.updateAgentCost(this.agentNo, params);
			if (result.success) {
				this.Message.success("修改成功");
				this.$emit("change", true);
			}
			this.loading = false;
		},
		nextClick() {
			console.log(this.checkList);
			if (this.checkList.length <= 0) {
				this.Message("请选择成本");
				return;
			}
			this.$emit("change", this.checkList, "next");
		},
		prevClick() {
			this.$emit("change", "", "prev");
		}
	}
};
</script>
